<template>
  <div>
    <app-header @getHeight="getHeight"></app-header>
    <div style="background-color: #fafafa">
      <div class="max_width min_width">
        <div class="flex align_center padtb_20" :style="{ 'margin-top': offsetHeight + 'px' }">
          <div class="f838 size_14">当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/serveProduct' }">服务产品</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/serveDetails' }">服务详情</el-breadcrumb-item>
            <el-breadcrumb-item class="" style="color: #ff6618">服务机构详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>

      <div style="background-color: #fafafa">
        <div class="max_width min_width">
          <div class="Introducebox" style="">
            <div style="display: flex; padding: 30px">

              <img :src="Introduce.img" style="
                  width: 280px;
                  height: 280px;
                  border-radius: 5px;
                  " alt="">


              <div style="margin-left: 30px; flex: 1">
                <div class="size_24" style="padding: 30px 0px">
                  {{ Introduce.name }}
                </div>
                <div class="size_18" style="padding-bottom: 20px">
                  服务地址：{{ Introduce.regLocation }}
                </div>
                <div class="size_18" style="padding-bottom: 20px">
                  服务产品:
                  <span class="c417">{{ Introduce.product_num }} 个</span>
                </div>
                <div class="size_18" style="padding-bottom: 20px">
                  累计订单数量:{{ Introduce.order_num }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="background-color: #fafafa">
        <div class="max_width min_width">
          <div style="
              width: 1518px;
              height: 166px;
              background: #ffffff;
              border-radius: 5px;
              margin: 30px 0px;
            ">
            <div class="dis align_center" style="padding: 30px">
              <div style="display: flex">
                <div class="icon"></div>
                <div style="margin-left: 20px">机构介绍</div>
              </div>
              <div style="
                  width: 968px;
                  background: #ffddcc;
                  border-radius: 5px;
                  box-shadow: 0px 2px 8px 0px rgba(0, 132, 255, 0.15);
                "></div>
            </div>
            <div style="margin: 0px 30px" v-if="Introduce.introduce">
              {{ Introduce.introduce }}
            </div>
            <div style="margin: 0px 30px" v-else>无</div>
          </div>
        </div>
      </div>

      <div style="background-color: #fafafa">
        <div class="max_width min_width " style="display: flex;">
          <div style="
              width: 272px;
              height: 294px;
              margin: 0px 10px 10px 0;
              background: #ffffff;
              border: 1px solid #f2f2f2;
              box-shadow: 0px 2px 8px 0px rgba(0, 132, 255, 0.15);
            " v-for="(item, index) in Introduces" :key="index" @click="goserveDetails(item.id)">
            <img :src="item.img" style="width: 270px; height: 171px" alt="" />
            <div class="size_18 c417" style="padding: 10px">
              {{ item.product_name }}
            </div>
            <div class="size_16" style="padding: 10px">
              {{ item.mode_price }}
            </div>
            <div style="display: flex;justify-content: space-between;padding: 0px 10px;color: #808080;
              " class="size_14">
              <div class="flex">
                <img src="../../assets/image/address.png" style="width: 16px; height: 18px" alt="" />
                <div class="marl_12">{{ item.city_name }}</div>
              </div>
              <div>{{ item.num }}次交易</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <el-pagination v-if="Introduces.length != 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="paginations.page_index" :page-sizes="paginations.page_sizes" :page-size="paginations.page_size"
        :layout="paginations.layout" :total="paginations.total">
      </el-pagination>
    </div>

    <app-footer />
  </div>
</template>
<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
export default {
  data() {
    return {
      offsetHeight: "",
      id: "",
      Introduce: [],
      Introduces: [],
      allIntroduces: [],
      ids: "",
      total: "",
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
    };
  },
  mounted() {
    this.id = this.$route.query;
    console.log(this.id, "this.id");
    this.mechanismInfo();
  },
  methods: {
    goserveDetails(id) {
      console.log(id);
      this.$router.push({ path: "/serveDetails", query: { id: id } });
    },
    productSelect() {
      let id = this.ids;
      let data = {
        id: id,
        page: this.paginations.page_index,
        limit: this.paginations.page_size,
      };

      this.$get("/mechanism_product_select", data).then((res) => {
        this.allIntroduces = res.result;
        if (res.count === "") {
          this.paginations.total = 0;
        } else {
          this.paginations.total = res.count;
        }
        if (this.allIntroduces.length != 0) {
          this.Introduces = this.allIntroduces.filter((item, index) => {
            return index < this.paginations.page_size;
          });
        } else {
          this.selectorder = [];
        }
        console.log(this.Introduces, "this.Introduces");
      });
    },
    getHeight(data) {
      console.log(data, "data");
      this.offsetHeight = data;
    },
    mechanismInfo() {
      let data = { id: this.id.id };

      this.$get("/select_mechanism_info", data).then((res) => {
        console.log(res, "res");
        this.Introduce = res.result;
        this.ids = res.result.id;
        console.log(this.ids, " this.ids");
        this.productSelect();
      });
    },
    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;

      this.Introduces = this.allIntroduces.filter((item, index) => {
        return index < page_size;
      });
    },

    // 分页

    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allIntroduces[i]) {
          tables.push(this.allIntroduces[i]);
        }
        this.Introduces = tables;
      }
    },


  },
  components: {
    "app-header": Header,
    "app-footer": Footer,
  },
};
</script>
<style scoped>
.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}

.Introducebox {
  width: 1518px;
  background: #ffffff;
  border-radius: 5px;
  background-image: url("../../assets/image/sIntroduceimg.png");
}

::v-deep.el-pagination {
  text-align: center;
  padding: 20px 0px;
  background-color: #fafafa;
}
</style>